import { __assign, __extends } from "tslib";
import "./fixPolyfills.js";
import { dep, wrap } from 'optimism';
import { ApolloCache } from "../core/cache.js";
import { MissingFieldError } from "../core/types/common.js";
import { addTypenameToDocument, isReference } from "../../utilities/index.js";
import { StoreReader } from "./readFromStore.js";
import { StoreWriter } from "./writeToStore.js";
import { EntityStore, supportsResultCaching } from "./entityStore.js";
import { makeVar, forgetCache, recallCache } from "./reactiveVars.js";
import { defaultDataIdFromObject, Policies } from "./policies.js";
import { hasOwn } from "./helpers.js";
var defaultConfig = {
  dataIdFromObject: defaultDataIdFromObject,
  addTypename: true,
  resultCaching: true,
  typePolicies: {}
};
var InMemoryCache = function (_super) {
  __extends(InMemoryCache, _super);
  function InMemoryCache(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this) || this;
    _this.watches = new Set();
    _this.typenameDocumentCache = new Map();
    _this.makeVar = makeVar;
    _this.txCount = 0;
    _this.maybeBroadcastWatch = wrap(function (c, fromOptimisticTransaction) {
      return _this.broadcastWatch.call(_this, c, !!fromOptimisticTransaction);
    }, {
      makeCacheKey: function (c) {
        var store = c.optimistic ? _this.optimisticData : _this.data;
        if (supportsResultCaching(store)) {
          var optimistic = c.optimistic,
            rootId = c.rootId,
            variables = c.variables;
          return store.makeCacheKey(c.query, c.callback, JSON.stringify({
            optimistic: optimistic,
            rootId: rootId,
            variables: variables
          }));
        }
      }
    });
    _this.watchDep = dep();
    _this.config = __assign(__assign({}, defaultConfig), config);
    _this.addTypename = !!_this.config.addTypename;
    _this.policies = new Policies({
      cache: _this,
      dataIdFromObject: _this.config.dataIdFromObject,
      possibleTypes: _this.config.possibleTypes,
      typePolicies: _this.config.typePolicies
    });
    _this.data = new EntityStore.Root({
      policies: _this.policies,
      resultCaching: _this.config.resultCaching
    });
    _this.optimisticData = _this.data;
    _this.storeWriter = new StoreWriter(_this, _this.storeReader = new StoreReader({
      cache: _this,
      addTypename: _this.addTypename
    }));
    return _this;
  }
  InMemoryCache.prototype.restore = function (data) {
    if (data) this.data.replace(data);
    return this;
  };
  InMemoryCache.prototype.extract = function (optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }
    return (optimistic ? this.optimisticData : this.data).extract();
  };
  InMemoryCache.prototype.read = function (options) {
    var _a = options.returnPartialData,
      returnPartialData = _a === void 0 ? false : _a;
    try {
      return this.storeReader.diffQueryAgainstStore({
        store: options.optimistic ? this.optimisticData : this.data,
        query: options.query,
        variables: options.variables,
        rootId: options.rootId,
        config: this.config,
        returnPartialData: returnPartialData
      }).result || null;
    } catch (e) {
      if (e instanceof MissingFieldError) {
        return null;
      }
      throw e;
    }
  };
  InMemoryCache.prototype.write = function (options) {
    try {
      ++this.txCount;
      return this.storeWriter.writeToStore({
        store: this.data,
        query: options.query,
        result: options.result,
        dataId: options.dataId,
        variables: options.variables
      });
    } finally {
      if (! --this.txCount && options.broadcast !== false) {
        this.broadcastWatches();
      }
    }
  };
  InMemoryCache.prototype.modify = function (options) {
    if (hasOwn.call(options, "id") && !options.id) {
      return false;
    }
    var store = options.optimistic ? this.optimisticData : this.data;
    try {
      ++this.txCount;
      return store.modify(options.id || "ROOT_QUERY", options.fields);
    } finally {
      if (! --this.txCount && options.broadcast !== false) {
        this.broadcastWatches();
      }
    }
  };
  InMemoryCache.prototype.diff = function (options) {
    return this.storeReader.diffQueryAgainstStore({
      store: options.optimistic ? this.optimisticData : this.data,
      rootId: options.id || "ROOT_QUERY",
      query: options.query,
      variables: options.variables,
      returnPartialData: options.returnPartialData,
      config: this.config
    });
  };
  InMemoryCache.prototype.watch = function (watch) {
    var _this = this;
    if (!this.watches.size) {
      recallCache(this);
    }
    this.watches.add(watch);
    if (watch.immediate) {
      this.maybeBroadcastWatch(watch);
    }
    return function () {
      if (_this.watches.delete(watch) && !_this.watches.size) {
        forgetCache(_this);
      }
      _this.watchDep.dirty(watch);
      _this.maybeBroadcastWatch.forget(watch);
    };
  };
  InMemoryCache.prototype.gc = function () {
    return this.optimisticData.gc();
  };
  InMemoryCache.prototype.retain = function (rootId, optimistic) {
    return (optimistic ? this.optimisticData : this.data).retain(rootId);
  };
  InMemoryCache.prototype.release = function (rootId, optimistic) {
    return (optimistic ? this.optimisticData : this.data).release(rootId);
  };
  InMemoryCache.prototype.identify = function (object) {
    return isReference(object) ? object.__ref : this.policies.identify(object)[0];
  };
  InMemoryCache.prototype.evict = function (options) {
    if (!options.id) {
      if (hasOwn.call(options, "id")) {
        return false;
      }
      options = __assign(__assign({}, options), {
        id: "ROOT_QUERY"
      });
    }
    try {
      ++this.txCount;
      return this.optimisticData.evict(options);
    } finally {
      if (! --this.txCount && options.broadcast !== false) {
        this.broadcastWatches();
      }
    }
  };
  InMemoryCache.prototype.reset = function () {
    this.data.clear();
    this.optimisticData = this.data;
    this.broadcastWatches();
    return Promise.resolve();
  };
  InMemoryCache.prototype.removeOptimistic = function (idToRemove) {
    var newOptimisticData = this.optimisticData.removeLayer(idToRemove);
    if (newOptimisticData !== this.optimisticData) {
      this.optimisticData = newOptimisticData;
      this.broadcastWatches();
    }
  };
  InMemoryCache.prototype.performTransaction = function (transaction, optimisticId) {
    var _this = this;
    var perform = function (layer) {
      var _a = _this,
        data = _a.data,
        optimisticData = _a.optimisticData;
      ++_this.txCount;
      if (layer) {
        _this.data = _this.optimisticData = layer;
      }
      try {
        transaction(_this);
      } finally {
        --_this.txCount;
        _this.data = data;
        _this.optimisticData = optimisticData;
      }
    };
    var fromOptimisticTransaction = false;
    if (typeof optimisticId === 'string') {
      this.optimisticData = this.optimisticData.addLayer(optimisticId, perform);
      fromOptimisticTransaction = true;
    } else if (optimisticId === null) {
      perform(this.data);
    } else {
      perform();
    }
    this.broadcastWatches(fromOptimisticTransaction);
  };
  InMemoryCache.prototype.transformDocument = function (document) {
    if (this.addTypename) {
      var result = this.typenameDocumentCache.get(document);
      if (!result) {
        result = addTypenameToDocument(document);
        this.typenameDocumentCache.set(document, result);
        this.typenameDocumentCache.set(result, result);
      }
      return result;
    }
    return document;
  };
  InMemoryCache.prototype.broadcastWatches = function (fromOptimisticTransaction) {
    var _this = this;
    if (!this.txCount) {
      this.watches.forEach(function (c) {
        return _this.maybeBroadcastWatch(c, fromOptimisticTransaction);
      });
    }
  };
  InMemoryCache.prototype.broadcastWatch = function (c, fromOptimisticTransaction) {
    this.watchDep.dirty(c);
    this.watchDep(c);
    var diff = this.diff({
      query: c.query,
      variables: c.variables,
      optimistic: c.optimistic
    });
    if (c.optimistic && fromOptimisticTransaction) {
      diff.fromOptimisticTransaction = true;
    }
    c.callback(diff);
  };
  return InMemoryCache;
}(ApolloCache);
export { InMemoryCache };
