import { wrap } from 'optimism';
import { getFragmentQueryDocument } from "../../utilities/index.js";
var ApolloCache = function () {
  function ApolloCache() {
    this.getFragmentDoc = wrap(getFragmentQueryDocument);
  }
  ApolloCache.prototype.recordOptimisticTransaction = function (transaction, optimisticId) {
    this.performTransaction(transaction, optimisticId);
  };
  ApolloCache.prototype.transformDocument = function (document) {
    return document;
  };
  ApolloCache.prototype.identify = function (object) {
    return;
  };
  ApolloCache.prototype.gc = function () {
    return [];
  };
  ApolloCache.prototype.modify = function (options) {
    return false;
  };
  ApolloCache.prototype.transformForLink = function (document) {
    return document;
  };
  ApolloCache.prototype.readQuery = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = !!options.optimistic;
    }
    return this.read({
      rootId: options.id || 'ROOT_QUERY',
      query: options.query,
      variables: options.variables,
      returnPartialData: options.returnPartialData,
      optimistic: optimistic
    });
  };
  ApolloCache.prototype.readFragment = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = !!options.optimistic;
    }
    return this.read({
      query: this.getFragmentDoc(options.fragment, options.fragmentName),
      variables: options.variables,
      rootId: options.id,
      returnPartialData: options.returnPartialData,
      optimistic: optimistic
    });
  };
  ApolloCache.prototype.writeQuery = function (options) {
    return this.write({
      dataId: options.id || 'ROOT_QUERY',
      result: options.data,
      query: options.query,
      variables: options.variables,
      broadcast: options.broadcast
    });
  };
  ApolloCache.prototype.writeFragment = function (options) {
    return this.write({
      dataId: options.id,
      result: options.data,
      variables: options.variables,
      query: this.getFragmentDoc(options.fragment, options.fragmentName),
      broadcast: options.broadcast
    });
  };
  return ApolloCache;
}();
export { ApolloCache };
