import { NetworkStatus } from "./networkStatus.js";
import { compact } from "../utilities/index.js";
import { invariant } from 'ts-invariant';
var Reobserver = function () {
  function Reobserver(observer, options, fetch, shouldFetch) {
    this.observer = observer;
    this.options = options;
    this.fetch = fetch;
    this.shouldFetch = shouldFetch;
  }
  Reobserver.prototype.reobserve = function (newOptions, newNetworkStatus) {
    if (newOptions) {
      this.updateOptions(newOptions);
    } else {
      this.updatePolling();
    }
    var concast = this.fetch(this.options, newNetworkStatus);
    if (this.concast) {
      this.concast.removeObserver(this.observer, true);
    }
    concast.addObserver(this.observer);
    return (this.concast = concast).promise;
  };
  Reobserver.prototype.updateOptions = function (newOptions) {
    Object.assign(this.options, compact(newOptions));
    this.updatePolling();
    return this;
  };
  Reobserver.prototype.stop = function () {
    if (this.concast) {
      this.concast.removeObserver(this.observer);
      delete this.concast;
    }
    if (this.pollingInfo) {
      clearTimeout(this.pollingInfo.timeout);
      this.options.pollInterval = 0;
      this.updatePolling();
    }
  };
  Reobserver.prototype.updatePolling = function () {
    var _this = this;
    var _a = this,
      pollingInfo = _a.pollingInfo,
      pollInterval = _a.options.pollInterval;
    if (!pollInterval) {
      if (pollingInfo) {
        clearTimeout(pollingInfo.timeout);
        delete this.pollingInfo;
      }
      return;
    }
    if (pollingInfo && pollingInfo.interval === pollInterval) {
      return;
    }
    process.env.NODE_ENV === "production" ? invariant(pollInterval, 20) : invariant(pollInterval, 'Attempted to start a polling query without a polling interval.');
    if (this.shouldFetch === false) {
      return;
    }
    var info = pollingInfo || (this.pollingInfo = {});
    info.interval = pollInterval;
    var maybeFetch = function () {
      if (_this.pollingInfo) {
        if (_this.shouldFetch && _this.shouldFetch()) {
          _this.reobserve({
            fetchPolicy: "network-only",
            nextFetchPolicy: _this.options.fetchPolicy || "cache-first"
          }, NetworkStatus.poll).then(poll, poll);
        } else {
          poll();
        }
      }
      ;
    };
    var poll = function () {
      var info = _this.pollingInfo;
      if (info) {
        clearTimeout(info.timeout);
        info.timeout = setTimeout(maybeFetch, info.interval);
      }
    };
    poll();
  };
  return Reobserver;
}();
export { Reobserver };
