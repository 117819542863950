import { __assign, __spreadArrays } from "tslib";
var hasOwnProperty = Object.prototype.hasOwnProperty;
export function mergeDeep() {
  var sources = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    sources[_i] = arguments[_i];
  }
  return mergeDeepArray(sources);
}
export function mergeDeepArray(sources) {
  var target = sources[0] || {};
  var count = sources.length;
  if (count > 1) {
    var merger = new DeepMerger();
    for (var i = 1; i < count; ++i) {
      target = merger.merge(target, sources[i]);
    }
  }
  return target;
}
function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}
var defaultReconciler = function (target, source, property) {
  return this.merge(target[property], source[property]);
};
var DeepMerger = function () {
  function DeepMerger(reconciler) {
    if (reconciler === void 0) {
      reconciler = defaultReconciler;
    }
    this.reconciler = reconciler;
    this.isObject = isObject;
    this.pastCopies = new Set();
  }
  DeepMerger.prototype.merge = function (target, source) {
    var _this = this;
    var context = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      context[_i - 2] = arguments[_i];
    }
    if (isObject(source) && isObject(target)) {
      Object.keys(source).forEach(function (sourceKey) {
        if (hasOwnProperty.call(target, sourceKey)) {
          var targetValue = target[sourceKey];
          if (source[sourceKey] !== targetValue) {
            var result = _this.reconciler.apply(_this, __spreadArrays([target, source, sourceKey], context));
            if (result !== targetValue) {
              target = _this.shallowCopyForMerge(target);
              target[sourceKey] = result;
            }
          }
        } else {
          target = _this.shallowCopyForMerge(target);
          target[sourceKey] = source[sourceKey];
        }
      });
      return target;
    }
    return source;
  };
  DeepMerger.prototype.shallowCopyForMerge = function (value) {
    if (isObject(value) && !this.pastCopies.has(value)) {
      if (Array.isArray(value)) {
        value = value.slice(0);
      } else {
        value = __assign({
          __proto__: Object.getPrototypeOf(value)
        }, value);
      }
      this.pastCopies.add(value);
    }
    return value;
  };
  return DeepMerger;
}();
export { DeepMerger };
